<template>
  <b-row>
    <b-col cols="4">
      <FoldersList
        :folder-type="'form'"
      />
    </b-col>
    <b-col cols="8">
      <FormList
        :form-type="'form'"
      />
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'
import FoldersList from '@/views/components/folders-list/FoldersList.vue'
import FormList from '@/views/admin/forms/form-list/FormList.vue'

export default {
  components: {
    BRow,
    BCol,
    FoldersList,
    FormList,
  },
}
</script>

<style>

</style>
